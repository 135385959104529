import React from "react";
import AboutUs from "../components/AboutUs"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const Testimonials = (props) =>{
    return(
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <AboutUs {...props} fields={props?.data} />
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(Testimonials);

export const query = graphql`
fragment TestimonialsFragment on MarkdownRemark {
      frontmatter {
      title
      testimonials {
        description
        userName
        date
        location
      }
    }
  }
  query TestimonialQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "testimonials-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "en-testimonials"}}) {
   ...TestimonialsFragment
  }
  deTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "de-testimonials"}}) {
   ...TestimonialsFragment
  }
  esTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "es-testimonials"}}) {
   ...TestimonialsFragment
  }
  frTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "fr-testimonials"}}) {
   ...TestimonialsFragment
  }
  heTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "he-testimonials"}}) {
   ...TestimonialsFragment
  }
  itTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "it-testimonials"}}) {
   ...TestimonialsFragment
  }
  jaTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "ja-testimonials"}}) {
   ...TestimonialsFragment
  }
  zhTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "zh-testimonials"}}) {
   ...TestimonialsFragment
  }
 }
`;